<template>
  <div>
    <Table
      title-key="orderNumber"
      :actions="actions"
      :items="$store.getters['donationV2/tableData']"
      :fields="$store.getters['donationV2/tableFields']"
      :loading="$store.state.donationV2.loadingTable"
      :filter="$store.state.donationV2.tableFilter"
      :sort="$store.state.donationV2.tableSort"
      :sort-options="sortOptions"
      :keyword="$store.state.donationV2.tableKeyword"
      :pagination="$store.state.donationV2.tablePagination"
      :tab-options="tabOptions"
      search-placeholder="Search supporter, order number"
      @changeFields="changeFields"
      @resetFields="resetFields"
      @filter="filterData"
      @sort="sortData"
      @load="loadData"
      @paginate="paginate"
      @search="search"
    />
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'

export default {
  components: {
    Table,
  },
  data() {
    return {
      // Sort
      sortOptions: [
        {
          key: 'start_date', direction: 'desc', label: 'Newest', default: true,
        },
        { key: 'start_date', direction: 'asc', label: 'Oldest' },
      ],

      // Tabs
      tabOptions: [
        {
          key: 'statuses', label: 'All', default: true,
        },
        { key: 'statuses', label: 'Created', value: 'CREATED' },
        {
          key: 'statuses', label: 'Succeeded', value: 'SUCCEED',
        },
        { key: 'statuses', label: 'Cancelled', value: 'CREATED_CANCELLED' },
        { key: 'statuses', label: 'Failed', value: 'FAILED' },
      ],

      actions: ['view', {
        type: 'other',
        children: [
          {
            label: 'Open in Stripe',
            event: this.openPaymentGateway,
            visibility: {
              callback: data => data.payment_src && data.payment_id && ['stripe', 'stripe_paynow', 'stripe_promptpay', 'stripe_google_play', 'stripe_applepay', 'stripe_wechat_pay'].includes(data.payment_src.toLowerCase()),
            },
          },
          {
            label: 'Open in senangPay',
            event: this.openPaymentGateway,
            visibility: {
              callback: data => data.payment_src && data.payment_id && ['senangpay'].includes(data.payment_src.toLowerCase()),
            },
          },
          {
            label: 'Open in Paypal',
            event: this.openPaymentGateway,
            visibility: {
              callback: data => data.payment_src && data.payment_id && ['paypal'].includes(data.payment_src.toLowerCase()),
            },
          },
          {
            label: 'Open in dragonpay',
            event: this.openPaymentGateway,
            visibility: {
              callback: data => data.payment_src && ['dragonpay_gcash'].includes(data.payment_src.toLowerCase()),
            },
          },
          {
            label: 'Open in Xendit',
            event: this.openPaymentGateway,
            visibility: {
              callback: data => data.payment_src && ['xendit'].includes(data.payment_src.toLowerCase()),
            },
          },
        ],
      }],
    }
  },
  methods: {
    openPaymentGateway(row) {
      const paymentType = row.item.payment_src.toLowerCase()
      const paymentId = row.item.payment_id

      if (['stripe', 'stripe_paynow', 'stripe_promptpay', 'stripe_google_play', 'stripe_applepay', 'stripe_wechat_pay'].includes(paymentType)) {
        // Stripe has the same URL format for both staging and live
        window.open(`https://dashboard.stripe.com/payments/${paymentId}`)
      } else if (paymentType === 'senangpay') {
        // SenangPay probably has the same URL format for both staging and live
        window.open(`https://app.senangpay.my/payment/receipt/${paymentId}`)
      } else if (paymentType === 'paypal') {
        window.open(`https://www.paypal.com/activity/payment/${paymentId}`)
      } else if (paymentType === 'dragonpay_gcash') {
        // dragonpay doesnt have a transaction details page, all links are the same
        window.open('https://gw.dragonpay.ph/AdminWeb/Main.aspx')
      } else if (paymentType === 'xendit') {
        const trimmedPaymentId = paymentId.replace(/-/g, '')
        window.open(`https://dashboard.xendit.co/payment-links/single/${trimmedPaymentId}`)
      }
    },
    changeFields(val) {
      this.$store.commit('donationV2/SET_TABLE_FIELDS', val)
    },
    loadData() {
      return this.$store.dispatch('donationV2/getTableData')
    },
    filterData(val) {
      this.$store.commit('donationV2/SET_TABLE_FILTER', val)
    },
    sortData(val) {
      this.$store.commit('donationV2/SET_TABLE_SORT', val)
    },
    paginate(val) {
      this.$store.commit('donationV2/SET_TABLE_PAGINATION', val)
    },
    search(val) {
      this.$store.commit('donationV2/SET_TABLE_KEYWORD', val)
    },
    resetFields() {
      this.$store.commit('donationV2/RESET_TABLE_FIELDS')
    },
  },
}
</script>
